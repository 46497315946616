html, body {
  margin: 0px;
  padding: 0px;
  width: 100dvw;
  height: 100dvh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*
  background-color: #fffe91;
  */
  /*
  background-color: #331a00;
  */
  background-color: rgb(91, 37, 8);
  background-image: url('images/amber.gif');
  background-repeat: repeat-both;
  background-position: top center;
  font-family: helvetica;
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
.mainFontColor {
  color: rgb(91, 37, 8);
}

h1,
h2,
.golden {
  color: rgb(232, 194, 44);
}

a:link {
  color: rgb(222, 114, 18);
}

a:visited {
  color: rgb(177, 91, 14);
}

#main_container {
  background-color: white;
  background-image: url('images/amber.gif');
  background-repeat: repeat-both;
  background-position: top center;
  width: 700px;
  border-left: 2px solid yellow;
  border-right: 2px solid yellow;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 80px 30px 80px;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  body {
    font-size: 14px;
  }

  #main_container {
    width: 100dvw;
    border: none;
    margin: 0;
  }
}

@media (max-width: 589px) {
  body {
    font-size: 16px;
  }

  #main_container {
    padding: 20px;
  }

  .nowPlayingHeader {
    font-size: 60%;
  }
}

.center {
  text-align: center;
}

a {
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: square;
}

#leopard_image {
  width: 300px;
  border: 2px solid yellow;
}

#leopard_image_container {
  float: right;
  text-align: center;
}

.small {
  border-top: 1px solid brown;
  font-size: 11px;
  border-bottom: 1px solid brown;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-color: rgb(232, 194, 44);
}

.songdiv {
  margin-bottom: 30px;
}
